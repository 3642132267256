.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.loader{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  background-color: rgba(255,255,255,.9);
  text-align: center;
  z-index: 2;
  vertical-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*flip clock*/
.flip-clock {
  display: flex;
}

.flip-clock-digit {
  width: 50px;
  height: 100px;
  background-color: #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  /* Add flip animation */
  transform-origin: 50% 100%;
  transform: perspective(300px) rotateX(90deg);
  transition: transform 0.5s;
}

.flip-clock-digit.active {
  transform: perspective(300px) rotateX(0deg);
}
.big-drop-shadow {
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.75);
}
.pre-contest-share-buttons{
  padding:1rem;
}
.pre-contest-share-buttons button{
  margin: .2rem
}
.pre-contest-copy-button{
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: solid 1px;
  width: 32px;
  height: 32px;
}
.p-2{
  padding: 1rem;
}
.p-3{
  padding: 1.5rem;
}
.pt-4{
    padding-top: 2rem;
}
.mb-4{
  margin-bottom: 2rem;
}
.m-2{
  margin: 1rem;
}
.forgot-pass-form{
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.forgot-pass-form div{
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}